<template>
  <Header />
  <main class="bg-gray-200">
    <div class="section section-lg pt-5 pt-md-7">
      <div class="container">
        <div class="row pt-5 pt-md-0">
          <Menu />
          <MenuMini />
          <div class="col-12 col-lg-9">
            <div class="row">
              <div class="col-12">
                <div class="card border-gray-300 text-center py-4 mb-4">
                  <div class="card-body">
                    <h2 class="display-3 mb-3">
                      CHART-IMG<span
                        class="pixel-pro-badge subscription-badge bg-tertiary fw-bolder text-white"
                        v-if="type"
                        >{{ type }}</span
                      >
                    </h2>
                    <div class="my-4" v-if="type && type !== 'BASIC'">
                      <p v-if="renew">
                        Next payment of
                        <span class="fw-bold"
                          >${{ price }} ({{ interval }}ly)</span
                        >
                        occurs on {{ fmtExpire }} (UTC).
                      </p>
                      <p v-else>
                        Your subscription
                        <span class="fw-bold">{{ type }}</span> will expire on
                        {{ fmtExpire }} (UTC).
                      </p>
                    </div>
                    <button
                      class="btn btn-sm btn-dark"
                      v-if="renew"
                      :disabled="btn.subscriptionCancel.disabled"
                      v-on:click="clickSubscriptionCancel"
                    >
                      Cancel subscription
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="type && type === 'BASIC'">
              <div class="col-12 col-md-3 mb-5 mb-lg-0">
                <div
                  class="card border-gray-300 text-center py-4 mb-4"
                  :class="{ 'bg-primary': selected === 'BASIC' }"
                >
                  <div class="card-header bg-white p-3">
                    <h3 class="text-primary mb-4">BASIC</h3>
                    <span class="d-block">
                      <span class="display-1 text-dark fw-bold"
                        ><span class="align-top font-medium">$</span>0</span
                      >
                      <span class="d-block text-gray font-small">/ month</span>
                    </span>
                  </div>
                  <div
                    class="card-body"
                    :class="{ 'text-white': selected === 'BASIC' }"
                  >
                    <ul class="list-unstyled mb-4">
                      <li class="list-item pb-3">
                        <strong>REST API</strong>
                      </li>
                      <li class="list-item pb-3">
                        Daily Limit<br />
                        <strong>100</strong>
                      </li>
                      <li class="list-item pb-3">
                        Rate Limit<br />
                        <strong>15</strong> / min
                      </li>
                      <li class="list-item pb-3">
                        Max<br />
                        <strong>800 x 600</strong>
                      </li>
                    </ul>
                    <div class="d-grid">
                      <button
                        v-on:click="clickSubscription('BASIC')"
                        :disabled="btn.subscribeBasic.disabled"
                        :class="btn.subscribeBasic.class"
                        class="btn animate-up-1"
                      >
                        SELECT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3 mb-5 mb-lg-0">
                <div
                  class="card border-gray-300 text-center py-4 mb-4"
                  :class="{ 'bg-primary': selected === 'PRO' }"
                >
                  <div class="card-header bg-white p-3">
                    <h3 class="text-primary mb-4">PRO</h3>
                    <span class="d-block"
                      ><span class="display-1 text-dark fw-bold"
                        ><span class="align-top font-medium">$</span>7 </span
                      ><span class="d-block text-gray font-small"
                        >/ month</span
                      ></span
                    >
                  </div>
                  <div
                    class="card-body"
                    :class="{ 'text-white': selected === 'PRO' }"
                  >
                    <ul class="list-unstyled mb-4">
                      <li class="list-item pb-3">
                        <strong>REST API</strong>
                      </li>
                      <li class="list-item pb-3">
                        Daily Limit<br />
                        <strong>1000</strong>
                      </li>
                      <li class="list-item pb-3">
                        Rate Limit<br />
                        <strong>60</strong> / min
                      </li>
                      <li class="list-item pb-3">
                        Max<br />
                        <strong>1920 x 1080</strong>
                      </li>
                    </ul>
                    <div class="d-grid">
                      <button
                        v-on:click="clickSubscription('PRO')"
                        :disabled="btn.subscribePro.disabled"
                        :class="btn.subscribePro.class"
                        class="btn animate-up-1"
                      >
                        SELECT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3 mb-5 mb-lg-0">
                <div
                  class="card border-gray-300 text-center py-4 mb-4"
                  :class="{ 'bg-primary': selected === 'MEGA' }"
                >
                  <div class="card-header bg-white p-3">
                    <h3 class="text-primary mb-4">MEGA</h3>
                    <span class="d-block"
                      ><span class="display-1 text-dark fw-bold"
                        ><span class="align-top font-medium">$</span>10 </span
                      ><span class="d-block text-gray font-small"
                        >/ month</span
                      ></span
                    >
                  </div>
                  <div
                    class="card-body"
                    :class="{ 'text-white': selected === 'MEGA' }"
                  >
                    <ul class="list-unstyled mb-4">
                      <li class="list-item pb-3">
                        <strong>REST API</strong>
                      </li>
                      <li class="list-item pb-3">
                        Daily Limit<br />
                        <strong>5000</strong>
                      </li>
                      <li class="list-item pb-3">
                        Rate Limit<br />
                        <strong>75</strong> / min
                      </li>
                      <li class="list-item pb-3">
                        Max<br />
                        <strong>2048 × 1080</strong>
                      </li>
                    </ul>
                    <div class="d-grid">
                      <button
                        v-on:click="clickSubscription('MEGA')"
                        :disabled="btn.subscribeMega.disabled"
                        :class="btn.subscribeMega.class"
                        class="btn animate-up-1"
                      >
                        SELECT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3 mb-5 mb-lg-0">
                <div
                  class="card border-gray-300 text-center py-4 mb-4"
                  :class="{ 'bg-primary': selected === 'ULTRA' }"
                >
                  <div class="card-header bg-white p-3">
                    <h3 class="text-primary mb-4">ULTRA</h3>
                    <span class="d-block"
                      ><span class="display-1 text-dark fw-bold"
                        ><span class="align-top font-medium">$</span>15 </span
                      ><span class="d-block text-gray font-small"
                        >/ month</span
                      ></span
                    >
                  </div>
                  <div
                    class="card-body"
                    :class="{ 'text-white': selected === 'ULTRA' }"
                  >
                    <ul class="list-unstyled mb-4">
                      <li class="list-item pb-3">
                        <strong>REST API</strong>
                      </li>
                      <li class="list-item pb-3">
                        Daily Limit<br />
                        <strong>10000</strong>
                      </li>
                      <li class="list-item pb-3">
                        Rate Limit<br />
                        <strong>100</strong> / min
                      </li>
                      <li class="list-item pb-3">
                        Max<br />
                        <strong>3840 x 2160</strong>
                      </li>
                    </ul>
                    <div class="d-grid">
                      <button
                        v-on:click="clickSubscription('ULTRA')"
                        :disabled="btn.subscribeUltra.disabled"
                        :class="btn.subscribeUltra.class"
                        class="btn animate-up-1"
                      >
                        SELECT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card border-gray-300 p-0 p-md-4 mb-4 text-center">
                  <div class="card-body">
                    <p>
                      If you need a higher request limit, please
                      <u
                        ><a href="mailto:support@chart-img.com"
                          >contact us</a
                        ></u
                      >
                      for an Enterprise plan.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="invoices && invoices.length > 0">
              <div class="col-12">
                <div class="card card-body bg-white border-gray-300 p-0 p-md-4">
                  <div class="card-header bg-white border-0 p-3">
                    <h3 class="h5">Billing History</h3>
                  </div>
                  <div class="card-body px-0 pt-0" v-if="invoices">
                    <ul class="list-group list-group-flush">
                      <li
                        class="list-group-item border-bottom rounded-0 py-3"
                        v-for="invoice in invoices"
                        :key="invoice"
                      >
                        <div class="row align-items-center">
                          <div class="col">
                            <h3 class="h6 mb-1">
                              #{{ invoice.number }}
                              <span
                                class="text-danger"
                                v-if="invoice.amountRemaining > 0"
                                >Payment Required</span
                              >
                            </h3>
                            <small class="text-gray-700"
                              >Billed
                              {{ fmtDate(invoice.createdAt) }} (UTC)</small
                            >
                          </div>
                          <div class="col-auto">
                            <a
                              :href="invoice.url"
                              target="_invoice"
                              class="btn btn-xs btn-outline-dark"
                            >
                              INVOICE
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="text-center" v-else>
                    <p>Loading...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Menu from '@/components/Menu'
import MenuMini from '@/components/MenuMini'
import moment from 'moment'

export default {
  components: {
    Header,
    Menu,
    MenuMini,
    Footer,
  },
  computed: {
    fmtExpire() {
      return this.expiredAt ? this.fmtDate(this.expiredAt) : 'null'
    },
  },
  methods: {
    fmtDate(date) {
      return moment(date).format('MMM Do, YYYY')
    },
    clickSubscriptionCancel() {
      this.setDisabled()
      this.subscriptionCancel()
        .then(() => this.setBasic())
        .catch((error) => this.$error(error))
    },
    clickSubscription(type) {
      const payload = { type: `${type}_MONTH` }

      this.setDisabled()
      this.$recaptcha()
        .then((token) => {
          return this.$http
            .post('/account/subscription', payload, {
              headers: {
                'ReCaptcha-token-v3': token,
              },
            })
            .then((res) => {
              window.location.href = res.data.url
            })
        })
        .catch((error) => this.$error(error))
    },
    subscriptionCancel() {
      return this.$recaptcha().then((token) => {
        return this.$http
          .delete('/account/subscription', {
            headers: {
              'ReCaptcha-token-v3': token,
            },
          })
          .then(() => {
            this.renew = false
          })
      })
    },
    getInvoices() {
      return this.$http.get('/account/subscription/invoice').then((res) => {
        this.invoices = res.data
      })
    },
    setType(type, renew) {
      if (type === 'BASIC' || !renew) {
        this.setBasic()
      } else if (type === 'PRO') {
        this.setPro()
      } else if (type === 'MEGA') {
        this.setMega()
      } else if (type === 'ULTRA') {
        this.setUltra()
      } else if (type === 'ENTERPRISE') {
        this.setEnterprise()
      }
    },
    setBasic() {
      this.selected = 'BASIC'
      this.btn.subscribeBasic.disabled = true
      this.btn.subscribePro.disabled = false
      this.btn.subscribeMega.disabled = false
      this.btn.subscribeUltra.disabled = false
      this.btn.subscriptionCancel.disabled = true

      this.btn.subscribeBasic.class = 'btn-success text-white'
      this.btn.subscribePro.class = 'btn-primary'
      this.btn.subscribeMega.class = 'btn-primary'
      this.btn.subscribeUltra.class = 'btn-primary'
    },
    setPro() {
      this.selected = 'PRO'
      this.btn.subscribeBasic.disabled = false
      this.btn.subscribePro.disabled = true
      this.btn.subscribeMega.disabled = false
      this.btn.subscribeUltra.disabled = false
      this.btn.subscriptionCancel.disabled = false

      this.btn.subscribeBasic.class = 'btn-primary'
      this.btn.subscribePro.class = 'btn-success text-white'
      this.btn.subscribeMega.class = 'btn-primary'
      this.btn.subscribeUltra.class = 'btn-primary'
    },
    setMega() {
      this.selected = 'MEGA'
      this.btn.subscribeBasic.disabled = false
      this.btn.subscribePro.disabled = false
      this.btn.subscribeMega.disabled = true
      this.btn.subscribeUltra.disabled = false
      this.btn.subscriptionCancel.disabled = false

      this.btn.subscribeBasic.class = 'btn-primary'
      this.btn.subscribePro.class = 'btn-primary'
      this.btn.subscribeMega.class = 'btn-success text-white'
      this.btn.subscribeUltra.class = 'btn-primary'
    },
    setUltra() {
      this.selected = 'ULTRA'
      this.btn.subscribeBasic.disabled = false
      this.btn.subscribePro.disabled = false
      this.btn.subscribeMega.disabled = false
      this.btn.subscribeUltra.disabled = true
      this.btn.subscriptionCancel.disabled = false

      this.btn.subscribeBasic.class = 'btn-primary'
      this.btn.subscribePro.class = 'btn-primary'
      this.btn.subscribeMega.class = 'btn-primary'
      this.btn.subscribeUltra.class = 'btn-success text-white'
    },
    setEnterprise() {
      this.btn.subscribeBasic.disabled = false
      this.btn.subscribePro.disabled = false
      this.btn.subscribeMega.disabled = false
      this.btn.subscribeUltra.disabled = false
      this.btn.subscriptionCancel.disabled = false
    },
    setDisabled() {
      this.btn.subscribeBasic.disabled = true
      this.btn.subscribePro.disabled = true
      this.btn.subscribeMega.disabled = true
      this.btn.subscribeUltra.disabled = true
      this.btn.subscriptionCancel.disabled = true
    },
  },
  mounted() {
    this.$http.get('/account/subscription').then((res) => {
      const { id, price, interval, expiredAt, renew } = res.data

      this.type = id
      this.price = price
      this.renew = renew
      this.interval = interval
      this.expiredAt = expiredAt ? new Date(expiredAt) : null
      this.setType(id, renew)

      return this.getInvoices()
    })
  },
  data() {
    return {
      type: null,
      renew: null,
      price: null,
      interval: null, // month, day
      expiredAt: null,
      invoices: null,
      selected: null,
      btn: {
        subscriptionCancel: {
          disabled: true,
        },
        subscribeBasic: {
          class: 'btn-primary',
          disabled: true,
        },
        subscribePro: {
          class: 'btn-primary',
          disabled: true,
        },
        subscribeMega: {
          class: 'btn-primary',
          disabled: true,
        },
        subscribeUltra: {
          class: 'btn-primary',
          disabled: true,
        },
      },
    }
  },
}
</script>
